import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import * as Api from "static/js/app/api/endpoints";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import ListingsHelpers from "static/js/app/templateHelpers/ListingsHelpers";
import SlickVehicleListingsGallery from "themes/premier-7/static/js/app/modules/vehicleListingsGallery";
import { LocalCache } from "static/js/app/utils/localStorageHelper";

export default class SoldGallery {
    private static sortTerms: { fieldName: string, isDescending: boolean }[];

    private static pageNumber: number;
    private static itemsPerPage: number;


    public static async init(siteConfig: SiteConfig, pageConfig:PageConfig, onTemplatesLoaded: () => void = null, availability: Models.Availability = "sold", vehicleStatus: Models.VehicleStatus= "all") {
        
  
        SoldGallery.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        SoldGallery.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);
        SoldGallery.sortTerms =SoldGallery.getSoldSortTerms();

        let searchCriteria = new Models.SearchCriteria(null, null, SoldGallery.sortTerms, SoldGallery.pageNumber, SoldGallery.itemsPerPage, availability,vehicleStatus);

        const searchResults = await Api.Vehicles.search(searchCriteria);

        let relativeRootSearchUrl = (availability == "sold") ? "/sold-gallery/" : (vehicleStatus ==  "new" ? "/new-vehicles/": "/used/");

        Page.ready(() => {
            SoldGallery.updatePageMetaDescription(siteConfig.seoTown, siteConfig.seoCounty, siteConfig.siteTitle);
            ListingsHelpers.synchronizeSearchInputs;            

            let relativeRootSearchUrl = "/sold-gallery/";

            Breadcrumbs.setBreadcrumbs(Breadcrumbs.getGalleryBreadcrumbs(relativeRootSearchUrl, "Sold Gallery"));

            const gridTemplate = "searchResultsTemplateGrid";
            const rowTemplate = "searchResultsTemplateRows";
            const defaultTemplate = "searchResultsTemplate";
            const placeholderImage = "/basemedia/placeholder.jpg";
            const templateId = ListingsHelpers.GetDefaultTemplate(pageConfig.default_listings_style);

            ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches, templateId, placeholderImage);
            ListingsHelpers.initListingsLayout(templateId,pageConfig.number_of_extra_images);


            const gridLayoutOption = document.getElementById(ListingsHelpers.gridLayoutButton);
            if (gridLayoutOption != null)
            {
                gridLayoutOption.onclick = function layoutOptionGrid() {
                ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches, ListingsHelpers.gridTemplate, placeholderImage);
                ListingsHelpers.initListingsLayout(ListingsHelpers.gridTemplate,pageConfig.number_of_extra_images);
                }
            }
            const rowsLayoutOption = document.getElementById(ListingsHelpers.rowLayoutButton);
            if (rowsLayoutOption !=null)
            {
                rowsLayoutOption.onclick = function layoutOptionRow() {
                ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches,ListingsHelpers.rowTemplate,placeholderImage);
                ListingsHelpers.initListingsLayout(ListingsHelpers.rowTemplate, pageConfig.number_of_extra_images);
                 
                 
             }
            }

            if ($(window).width() < 767) {
                ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches, ListingsHelpers.gridTemplate,placeholderImage);
                ListingsHelpers.initListingsLayout(ListingsHelpers.gridTemplate, pageConfig.number_of_extra_images);
            }
            $(window).on("resize", function() {

                if ($(window).width() < 767) {
                    ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches, ListingsHelpers.gridTemplate,placeholderImage);
                    ListingsHelpers.initListingsLayout(ListingsHelpers.gridTemplate, pageConfig.number_of_extra_images);
                }
            });
            
            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            } 

            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            }
            
            ListingsHelpers.initializeDefaultPager(
                searchResults.totalPages,
                SoldGallery.pageNumber,
                (Page.queryString["items-per-page"] != null ? parseInt(Page.queryString["items-per-page"]): null),
                `${window.location.protocol}//${window.location.host}`
            );
        });
    }
    private static getSoldSortTerms(): { fieldName: string, isDescending: boolean }[] {
        return [{ fieldName: 'sold_date', isDescending: true }];
    }
    private static getPageMetaDescription(seoTown: string, seoCounty: string, siteTitle: string): string {

        return `Check out our previously sold vehicles at ${siteTitle} in ${seoTown}, ${seoCounty}`;
    }

    private static updatePageMetaDescription(seoTown: string, seoCounty: string, siteTitle: string) {
        $('meta[name="description"]').attr('content', SoldGallery.getPageMetaDescription(seoTown, seoCounty, siteTitle));
    }

    

 
  
}
